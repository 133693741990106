import React from "react"
import ActionForm from "../components/action-form"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Job = ({
  title,
  location,
  geringfuegig = false,
  sales = false,
  referral = true,
  workingTime,
  startDate,
  annualSalary,
  children,
}) => {
  return (
    <Layout>
      <SEO
        title={title + " (f/x/m) at Celantur GmbH"}
        description={
          "Join our team at Celantur as a " +
          title +
          ", build world-class AI-powered technology to protect people's privacy."
        }
      />

      <section className="container pt-8 pt-md-12 pb-8 pb-md-12 bg-gradient text-center">
        <div className="container">
          <h1 className="display-3 font-weight-bold">
            {title} (f/x/m){" "}
            <span role="img" aria-label="notebook">
              💻{" "}
            </span>
          </h1>
          <p className="lead font-weight-bold mb-6 mb-md-8 text-gray-800">
            Build world-class technology to protect people's privacy
          </p>
          <span className="badge badge-dark-soft badge-pill mb-3">
            <span className="h6 text-uppercase">{location}</span>
          </span>{" "}
          <span className="badge badge-dark-soft badge-pill mb-3">
            <span className="h6 text-uppercase">{workingTime}</span>
          </span>{" "}
          <span className="badge badge-dark-soft badge-pill mb-3">
            <span className="h6 text-uppercase">{startDate}</span>
          </span>
        </div>
      </section>

      <section className="pt-5 pt-md-8">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h3>Who we are</h3>

              <p className="text-gray-800">
                Celantur is a Linz-based Privacy Tech &amp; Computer Vision Startup that develops anonymization
                solutions for images &amp; videos.
              </p>
              <p className="text-gray-800">
                Our mission is to help companies that collect massive volumes of image &amp; video data to protect
                individuals’ privacy. Our solutions are used by SMEs, large enterprises and municipalities active in
                reality capture, construction and automotive, for example Daimler Truck, STRABAG, DPDgroup and the City
                of Detroit.
              </p>
              <p className="text-gray-800 mb-6 mb-md-8">
                We are an engineering-driven team, passionate about building world-class technology to protect people's
                privacy.
              </p>

              {children}

              {geringfuegig ? (
                <p className="my-5 text-gray-800">Salary for 7 hours per week is EUR 5,376 gross per year.</p>
              ) : (
                <p className="my-5 text-gray-800">
                  The minimum salary for this position is {annualSalary} gross per year based on full-time employment
                  (38.5 h/week). We offer a higher salary in line with qualifications and experience.
                </p>
              )}

              <div className="mb-8">
                <ActionForm
                  source="job"
                  headline="🚀 One-Click Application - "
                  placeholderInput="Write us a short message about yourself, the position you are applying for and a link to your LinkedIn/CV/website."
                  labelSubmitButton="Apply"
                  emoji="📄"
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card shadow-lift mb-5 card-border card-border-xl border-success">
                <div className="card-body">
                  <h4>Apply</h4>

                  <p className="font-size-sm text-gray-800 mb-5">
                    Tell us via email why you think you're a perfect fit for our team and include your CV.
                  </p>

                  <p className="font-size-sm text-gray-800 mb-0">
                    Contact:
                    <br />
                    Alexander Petkov
                    <br />
                    <a href="mailto:hello@celantur.com">hello@celantur.com</a>
                  </p>
                </div>
              </div>

              <div className="card shadow-lift mb-5 card-border border-primary bg-gray-300">
                <div className="card-body">
                  <h4>{referral ? "€500 Referral Bonus 🤝" : "Referral"}</h4>
                  <p className="font-size-sm text-gray-800 mb-3">
                    Do you know a person who might be a perfect fit for this role?{" "}
                    <a href="mailto:hello@celantur.com">Let us know</a>
                    {referral ? " and get a €500 referral bonus" : ""}.
                  </p>
                </div>
              </div>

              <div className="card shadow-lift">
                <div className="card-body">
                  <h4>Don't see a job for you?</h4>

                  <p className="font-size-sm text-gray-800">
                    Do you feel like you belong working with Celantur, but we just don’t have your dream job posted? No
                    problem, <a href="mailto:hello@celantur.com">just reach out</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Job
