import React from "react"

import Job from "../components/job"
import {
  JobFeature,
  JobRole,
  JobRequirements,
} from "../components/job-components"

const JobImageAnnotator = () => {
  return (
    <Job
      title="Image Annotator, Internship/Student Job"
      location="Linz, Austria"
      workingTime="Part-time (geringfügig)"
      geringfuegig
      startDate="ASAP"
      referral={false}
    >
      <h3 className="my-5">Engineering at Celantur</h3>
      <JobFeature>
        Annotate images with precision and consistency based on provided guidelines.
      </JobFeature>
      <JobFeature>
        Understand project requirements and provide valuable insights.{" "}
      </JobFeature>
      <JobFeature>
        Gain an advanced understanding of use cases and how to translate them into training data.
      </JobFeature>
      <JobFeature>
        Perform quality checks to ensure the accuracy of annotations and maintain high data integrity.
      </JobFeature>
      <JobFeature>
        Assist in continuously improving the annotation process and tools used for the task.
      </JobFeature>

      <JobRole>
        <JobFeature>
          Strong attention to detail and the ability to maintain accuracy even in repetitive tasks.
        </JobFeature>
        <JobFeature>
          Familiarity with image annotation tools (Label Studio) and related software would be an advantage.
        </JobFeature>
        <JobFeature>
          Make judgment calls on difficult edge cases that might come up during labelling and annotation.
        </JobFeature>
        <JobFeature>
          High degree of confidentiality, as you’ll be working with sensible data.
        </JobFeature>
        <JobFeature>
          Basic understanding of computer vision and machine learning concepts is a plus.
        </JobFeature>
        <JobFeature>
          University or technical college students are preferred.
        </JobFeature>
        <JobFeature>
          Proficiency in Python is a huge plus.
        </JobFeature>
        <JobFeature>
          Excellent communication and collaboration skills to work effectively in a team environment.
        </JobFeature>
        <JobFeature>
          Interest in data protection regulations and privacy issues, ensuring the security and confidentiality of our data.
        </JobFeature>
        <JobFeature>
          Strong interest in industry use cases, such as automotive and construction, is a bonus, but not a strict requirement.
        </JobFeature>

      </JobRole>

      <h3 className="my-5">What we offer</h3>
      <JobFeature>
        You’ll work with cutting-edge hardware and software.
        We’ve set up a customized data annotation system,
        with Label Studio facilitating Meta’s Segment Anything Model (SAM) and
        internal models to semi-automate and speed up the annotation process.
      </JobFeature>
      <JobFeature>
        Purpose and impact: Your contributions improve software that helps protect the privacy of individuals
        on hundreds of millions of images and videos on four continents.
      </JobFeature>
      <JobFeature>
        As part of our RnD team, you’ll gain a lot of insights into how ML
        products are being built and put into production on a global scale.
      </JobFeature>
      <JobFeature>
        A collaborative and inclusive work environment that fosters growth and learning.
      </JobFeature>
      <JobFeature>An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)</JobFeature>
    </Job>
  )
}

export default JobImageAnnotator
